import React from 'react'
import { useNavigate } from 'react-router-dom'
import Compra from '../component/compra/Compra'
import { useCart } from '../context/CartContext'
import './cart.css'

const Cart = () => {
    const{cart, emptyCart, totalCart}=useCart()
    const navegar=useNavigate()

  return (
    <>
        {!cart.length?
        <div style={{textAlign:"center", minHeight:'60.5vh'}}>
            <h2>Tu carrito está vacío</h2>
            <div className='container'>
              <a className='button' style={{color:"#1e9bff"}} href="" onClick={()=> navegar('/home')}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                Ir a Obras de Arte
              </a>
            </div>

            
            {/* <button  onClick={()=> navegar('/home')}
             >Ir a Obras de Arte</button> */}
            <p>Bienvenid@s a nuestra galeria</p>
        </div>
        :
        <div>
            <h2>Mi carrito</h2>
            <hr />
            <div>
                 {cart.map((compra)=><Compra key={compra.id} compra={compra} /> ) } 
                <p>Total a pagar :${totalCart()} </p>
                <hr />
                <button onClick={emptyCart} >Borrar carrito</button>
                <button onClick={()=> navegar('/checkout')} >Iniciar compra</button>
            </div>
        </div>
        }
    </>
  )
}

export default Cart