import logo from './logo.svg';
import './App.css';
import Home from './component/Home';
import Nav from './nav/Nav';
import Logocabecera from './component/logocabecera/Logocabecera';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import { Fragment } from 'react';
import Whois from './component/quienes/Whois';
import Organization from './component/organizacion/Organization';
import Expositiones from './component/exposiciones/Expositiones';
import Fecht from './fecht/Fecht';
import Header from './navbar/Header';
import DetalleContainer from './detalle/DetalleContainer';
import { CartProvider } from './context/CartContext';
import CartWidget from './component/cartWidget/CartWidget';
import Cart from './views/Cart';
import Checkout from './views/Checkout';
import Calls from './component/convocatorias/Calls';

function App( ) {
  
  //  const navegar= useNavigate()

  return (
    <Fragment>
      <CartProvider>
    <BrowserRouter>
      {/* <Logocabecera/> */}
      {/* <Nav/> */}
      <Header/>
      {/* <CartWidget/> */}
      <Routes>
        <Route path='/home' element={<Fecht/>} />
        <Route path='/quienes' element={<Whois/>} />
        <Route path='/organizacion' element={<Organization/>}/>
        <Route path='/exposiciones' element={<Expositiones/>} />
        <Route path='/convocatorias' element={<Calls/>} /> 
        {/* <Route path='/filtro/:category' element={<Fecht/>} /> */}
        <Route path='/detalle/:id' element={<DetalleContainer/>} />
        <Route path='/cart' element={<Cart/>} />
        <Route path='/checkout' element={<Checkout/> } />
      </Routes>
      </BrowserRouter>
      <div style={{textAlign:"center"}}>
        {/* <h3 >Nair y Nap</h3> */}
      {/* <p style={{textAlign:"center"}}>Galería de Arte - Asociación Cultural Nair y Nap</p>
      <p>Ver Exposición "Mujeres del Alma Mía"   
           <a href="/home" 
          target=""
          >Click aquí</a> 
        </p> */}
      {/* <button onClick={()=> navegar('/home')}>Exposición "Mujeres de Alma Mía"</button> */}
      </div>
      
      </CartProvider>
    </Fragment>
  );
}

export default App;
