import React from 'react'
import './Whois.css'

const Whois = () => {
  return (
    <div className='quien' style={{color:"black"}}>
      <div className='quienes-somos'>
        <p><strong>Nair y Nap Perú</strong> , es un colectivo de artistas plásticos 
          y visuales que nace en el departamento de Piura-Perú, 
          que se han agrupado con la finalidad de difundir el arte en 
          Latinoamérica y realizar intercambios culturales y artísticos.
          En el Perú cada una de las ciudades que se constituya un grupo 
          de artistas se le denominará capítulo. Actualmente cuenta con 
          un gran numero de miembros entre Piura, Cajamarca, Lima, Arequipa 
          y Cuzco.
        </p>
        <br />

        <h4>NAIR Y NAP,REFERENCIA HISTORICA DELNOMBRE DEL PROYECTO OTORGADO POR LA ESCRITORA 
          Y CREADORA DE ENERGETRAS :ANAMARY ALVEREZ :</h4>
        <p>La investigadora y escritora española creadora de ENERGETRAS en Piura, Perú, 
          ANAMARY ALVAREZ AZCARATE ,le otorga al proyecto el nombre de "Nair y Nap" por lo siguiente:
          “La cultura Tallan floreció en Piura, la región norte del Perú, fue una cultura Precolombina y Preinca,
          tuvo su propias lengua, el idioma sec, los Tallanes denominaban a la luna Nair y al sol Nap.Para los 
           Tallanes Nair y Nap era una dualidad y los adoraban como dioses, pero su Dios principal era Ñariwalac,
         Dios del ojo visor, pues solo tenia un ojo, era cíclope.
          Esta cultura fue un matriarcado y la cacica Tallapoma era la que mandaba la región.
          A la conquista del Tawantinsuyo a los ibéricos les parecieron y recordaron a los monjes capuchinos 
          por sus mantos de algodón, que la envolvían de la cabeza a los pies y por tal les llamaron capuchinas 
          derivándose al nombre de capullanas, fueron aguerridas y se casaron las veces que creyeron conveniente
           para su cacicazgo. 
          Por tal eran Capullanas de Sol y Luna o cacicas Tallapomas de Nair y Nap.”</p>
      </div>
    </div>
  )
}

export default Whois