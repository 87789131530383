import React from 'react'

const ItemCounter = ({stock,onAdd,counter,setCounter}) => {
  return (
    <div>
        <div>
            <button onClick={onAdd} >comprar</button>
            {/* <button onClick={()=> setCounter(counter +1)} disabled={counter>stock} >+</button>
            <span>{counter} </span>
            <button onClick={()=> setCounter(counter-1)} disabled={counter<1} >-</button> */}
        </div>
    </div>
  )
}

export default ItemCounter