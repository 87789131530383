import React from "react";
import { NavbarWrapper } from "./styles/NavbarStyles";
import { NavLink } from 'react-router-dom'
import CartWidget from "../component/cartWidget/CartWidget";
import { useCart } from "../context/CartContext";

function Navbar({ open }) {
  // const{cart}= useCart()
  //   console.log(cart)
  return (
    <NavbarWrapper open={open} >
        {/* <ul className=''>
                <li className=''>
                  
                </li>
                <li className=''>
                  
                </li>
                <li className=''>
                                 
                </li>
                <li className=''>
                                  
                </li>     
            </ul> */}
      <a href="#"><NavLink to='/home'>Home</NavLink></a>
      <a href="#"><NavLink to='/quienes'>Quienes Somos</NavLink></a>
      <a href="#"><NavLink to='/organizacion'>Organización</NavLink> </a>
      <a href="#"><NavLink to='/exposiciones'>Exposiciones</NavLink> </a> 
      <a href="#"><NavLink to='/convocatorias'>Convocatorias</NavLink> </a> 
      {/* <NavLink><CartWidget/> </NavLink>   */}
      
    </NavbarWrapper>
    
  );
}

export default Navbar;