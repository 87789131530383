import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import List from '../list/List'
const Fecht = () => {
    const [personajes, setPersonajes] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const {category}=useParams

    
    useEffect(()=>{
        axios.get("https://63bcdfd4d660062388945b39.mockapi.io/nair")
        .then((res)=>{
        if(!category){
            setPersonajes(res.data)
        }else{
            setPersonajes(res.data.filter((personajes)=>personajes.category===category))           
        }
    })
    
    .catch((error)=> setError(true))   
    .finally(()=>setLoading(false))
    },[category])
    

  return (
    <div style={{color:"", margin:"10px" }}>
        <h6 style={{textAlign:"center"}}>Artistas participantes - Exposición "Singularidades"</h6>
        {/*<p style={{textAlign:"center",fontSize:"14px"}}>Ver Catálogo en pdf    
           <a href="https://drive.google.com/file/d/1xHQiAikfM099N-Br4RqXQuZRtAidJBUS/view?usp=sharing" 
          target="blank"
          > Click aquí</a> 
  </p> */}
        {loading? <p>Loading...</p>  
        :error? <p>Error, vuelve a cargar</p> 
        :<List personajes={personajes} /> } 
    </div>
  )
}

export default Fecht