import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCart } from '../context/CartContext'
import ItemCounter from '../itemCounter/ItemCounter'

const Detalle = ({detalle}) => {
    const {id, image, price, name, description, stock, codigo, medidas}= detalle
    const [counter, setCounter]=useState(1)
    const [purchase, setPurchase]=useState(false)
    const navigate=useNavigate()
    const {addToCart}=useCart()
    const onAdd=()=>{
        let item={
            name,
            id,
            price,
            codigo,
            image,
            quantity: counter
        }
        setPurchase(true)
        //console.log('compraste:', item)
        addToCart(item)
    }
    return (
    <div>
        <div style={{textAlign:"center"}}>
            <img src={image} height="300" />
            <div>
                <p>Título: "{name} "</p>
                <p>Autor: {codigo} </p>
                <p>Precio: $ {price} </p>
                <p>Medidas: {medidas} </p>
                <p>Descripción: {description} </p>
            </div>
            {
                purchase? <div>
                    <button onClick={()=> navigate('/home')} >Seguir comprando</button>
                    <button onClick={()=> navigate('/cart')} >Ir al carrito</button>
                </div>
                : <ItemCounter stock={stock} onAdd={onAdd} counter={counter} setCounter={setCounter}/>
            }
        </div>
    </div>
  )
}

export default Detalle