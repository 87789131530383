import React from 'react'

const Calls = () => {
  return (
    <div>
        <div>
            <h4 style={{textAlign:"center"}}>Alemania - Sala María Reiche</h4>
            <p style={{textAlign:"center",fontSize:"14px"}}>Ver bases en pdf    
            <a href="https://drive.google.com/file/d/1yG_sj3-3JXGBJW9uMCLIx7AFI-LQ5q-E/view?usp=sharing" 
            target="blank"
            > Click aquí</a> 
            </p>
        </div>
        <br />
        {/* <div>
            <h4 style={{textAlign:"center"}}>España - Castel de Sant Ferran de figueres</h4>
            <p style={{textAlign:"center",fontSize:"14px"}}>Ver bases en pdf    
            <a href="https://drive.google.com/file/d/1Ag33rI9J_ZvvJDJ0QVZPQApRvSllEUNB/view?usp=sharing" 
            target="blank"
            > Click aquí</a> 
            </p>
        </div> */}
        <br />
        <div>
            <h4 style={{textAlign:"center"}}>México - Sala del Faro Antiguo de Isla Aguada</h4>
            <p style={{textAlign:"center",fontSize:"14px"}}>Ver bases en pdf    
            <a href="https://drive.google.com/file/d/1JyiXGxn9y7DfaK637RmtbQFABrBr7K_Q/view?usp=sharing" 
            target="blank"
            > Click aquí</a> 
            </p>
        </div>
    </div>
  )
}

export default Calls