import React, { useState } from "react";
 import { HeaderWrapper } from "./styles/Header";
import logonair from './logo2023.png'
import Navbar from "./Navbar";
import MenuButton from "./MenuButton";
import { Link, NavLink } from "react-router-dom";
import CartWidget from "../component/cartWidget/CartWidget";
import { useCart } from "../context/CartContext";

function Header() {
  const [open, setOpen] = useState(false);
  // const{cart}= useCart()

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <HeaderWrapper>
      <NavLink to='/home'>
        <a href="">
          <img src={logonair} height='80'/>
        </a>
        {/* <h2 style={{color:"#976506",background:"", width:"30vh", paddingLeft:"20px" }}>
        
         Nair y Nap</h2> */}
         </NavLink>
      <Navbar open={open} />
      <MenuButton open={open} handleClick={handleClick} />
      <NavLink to="/cart" ><CartWidget/> </NavLink> 
    </HeaderWrapper>
  );
}

export default Header;