import React from 'react'
import {BiCart} from 'react-icons/bi';
import { useCart } from '../../context/CartContext'

const CartWidget = () => {
    const {cartQuantity, cart}=useCart()
  return (
    <div>
        {/* cartwidget */}
         <BiCart style={{width:"2rem",height:"2rem", color:'black' }}/>
        {/* {!cart.lenght ? '' : <span>{cartQuantity()||""} </span> } */}
        {!cart.length ? '' : <span style={{backgroundColor:'green', color:'white', padding:'.3rem', borderRadius:'180%'}}>{cartQuantity()|| "" } </span>}
    </div>
  )
}

export default CartWidget