import React from 'react'
import { useCart } from '../../context/CartContext'

const Compra = ({compra}) => {
  const {removeItem}=useCart
  return (
    <div>
      <img src={compra.image} height="50" alt={compra.name} />
      <p>${compra.price} </p>
      <p>{compra.quantity} </p>
      <button onClick={()=> removeItem(compra.id) } >x</button>
    </div>
  )
}

export default Compra