import React from 'react'

const Organization = () => {
  return (
    <div style={{textAlign:"center"}}>
        <p>Directora Nacional:</p>
        <p>Elizabeth Castro Olea</p>
        <br /> 
        <p>Directora de Organización:</p>
        <p>Rebeca Dorich</p>
        <br />
        <p>Director Capitulo Norte:</p>
        <p>Alex Camacho Nuñez</p>
        <br />
    </div>
  )
}

export default Organization