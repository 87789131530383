import React from 'react'
import { useNavigate } from 'react-router-dom'

const Card = ({personaje}) => {
    const navegar = useNavigate()
    
  return (
    <div 
    style={{width:'8rem', margin:'1rem',background:"", textAlign:'center', justifyContent:'center'}}
    >
        {/* Card */}
        <img src={personaje.image} height="130" />
        <div>
            {/* <h5>{personaje.name} </h5> */}
            {/* <p>precio ${personaje.price}</p> */}
        </div>
            <button onClick={()=>navegar(`/detalle/${personaje.id}`)}>Ver mas</button>
    </div>
  )
}

export default Card