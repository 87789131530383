import React from 'react'
import { useState } from 'react'
import './Exposiciones.css'

const Expositiones = () => {
  const [show, setShow]=useState(false)
  const [tres, setTres]=useState(false)

  const handleShw=()=>{
    setShow(!show)
  }
  const veintetres=()=>{
    setTres(!tres)
  }
  return (
    <div className='exposicion'>      
      <div style={{}}>
        {tres&& 
        <div>
          <p><li>"Mujeres del Alma Mía" - Museo Municipal Vicús</li> </p>
          {/* <p><li></li> </p>
          <p><li></li> </p> */}
        </div>
        }
        <div>
        <button onClick={veintetres} > {tres?'leer menos':'2023' } </button></div>
        
      </div>
      <div style={{}}>
        {show&& 
        <div>
          <p><li>"Expresiones del color" - Centro Piurano-Piura</li> </p>
          <p><li>"En el Quadratus" - Coleccionista Piura</li> </p>
          <p><li>II Salón Internacional de Arte Infantil y Juvenil "...Y dos años despues" Museo Vicus</li> </p>
        </div>
        }
        <div>
        <button onClick={handleShw} > {show?'leer menos':'2022' } </button></div>
        
      </div>
      
      
    </div>
  )
}

export default Expositiones