import React from 'react'
import { useCart } from '../context/CartContext';
import {FaWhatsapp } from "react-icons/fa";
import PedidoCompleto from '../component/compra/PedidoCompleto';

const Checkout = (props) => {
  let producto1=[];
  let precio1=[];
  let codigo=[];
  let total=0;
  const{cart, totalCart,}=useCart()
  for (let i=0; i<cart.length; i++){
    producto1[i]=cart[i].name
    precio1[i]=cart[i].price
    codigo[i]=cart[i].codigo
  }
  return (
    <div>
      <div>
        <h3>Detalle de tu compra</h3>
        <hr />
        <div>
          {cart.map((pedidocompleto)=> <PedidoCompleto key={pedidocompleto.id} pedidocompleto={pedidocompleto} /> )}
          <p>total a pagar: ${total= totalCart() } </p>
        </div>
      </div>
      <button>
      <a style={{color:'white', textDecoration:'none', background:"green"}} 
          target="_blank" rel="noreferrer" href={`http://wa.me/+51942393308/?text=Tu pedido:%0A `
           + producto1 + `%0A $:` + precio1 +`%0A codigo:`+ codigo  +`%0A Total :`+ total +`%0A ¿Cuál es tu medio de pago? :`} 
           
          > 
          <FaWhatsapp/> Completar Pedido en WhatsApp</a> 
      </button>
    </div>
  )
}

export default Checkout