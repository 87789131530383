import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import Detalle from './Detalle'

const DetalleContainer = () => {
    const[detalle,setDetalle]= useState({})//variable del estado
    const {id}=useParams()
    const navigate=useNavigate()

    useEffect(()=>{
        axios.get(`https://63bcdfd4d660062388945b39.mockapi.io/nair/${id}`)
        .then((res)=>setDetalle(res.data))
        .catch((error)=>{
            console.log(error)
            navigate('/error')
        })//atrapa el error
    },[id])

  return (
    <div>
        <Detalle detalle={detalle} />
    </div>
  )
}

export default DetalleContainer